import utilities from '../utilities';
import videojs from 'video.js';

const Plugin = videojs.getPlugin('plugin');

class PersistVolumePlugin extends Plugin {
  constructor(player, options) {
    super(player, options);
    if (!checkLocalStorageAvailability()) {
      return
    }

    let defaults = {
      namespace: 'vjs'
    }
    let settings = utilities.merge(defaults, options)

    let volKey = `${settings.namespace}-volume`;
    let muteKey = `${settings.namespace}-mute`;

    player.on('volumechange', function () {
      localStorage.setItem(volKey, player.volume());
      localStorage.setItem(muteKey, player.muted());
    });

    player.volume(localStorage.getItem(volKey) || 1);
    player.muted(localStorage.getItem(muteKey) === 'true');
  }
}

function checkLocalStorageAvailability() {
  let test = 'test';
  try {
    localStorage.setItem(test, test);
    if (localStorage.getItem(test) === test) {
      localStorage.removeItem(test);
      return true;
    }
    return false;
  } catch (e) {
    return false;
  }
}

videojs.registerPlugin('persistVolume', PersistVolumePlugin);
