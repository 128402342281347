module.exports = class Logger {
  static instance = null;
  static enabled = true;
  permissions = [];
  readyPromise = null;
  breadcrumbs = [];

  constructor() {
    const oldOnError = window.onerror;

    const handleRejection = (err) => {
      this.captureEvent(err);
    };

    window.addEventListener(
      'unhandledrejection',
      function (promiseRejectionEvent) {
        promiseRejectionEvent.promise.catch(handleRejection);
      }
    );

    window.onerror = function (message, url, line, col, e) {
      oldOnError && oldOnError(message, url, line, col, e);
      handleRejection(e);
    }.bind(this);
  }

  addPermissionChecker(permission) {
    if (typeof permission === 'function') {
      this.permissions.push(permission);
    }
  }

  checkSendingPermission(event, exp) {
    let valid = true;

    for (let i = 0; i < this.permissions.length; i++) {
      if (!valid) {
        return valid;
      }
      valid = this.permissions[i](event, exp);
    }
    return valid;
  }

  get allowToSendError() {
    const SAMPLE_RATE = parseFloat(process.env.SENTRY_SAMPLE_RATE); // value from 0 to 100
    const sendProbabilityRandom = Math.random() * 100;
    return sendProbabilityRandom <= SAMPLE_RATE;
  }

  async loadSentry() {
    if (this.readyPromise) return this.readyPromise;
    if (!this.allowToSendError) return Promise.resolve(false);
    if (window.Sentry) {
      this.createSentry();
      this.readyPromise = Promise.resolve(true);
      return this.readyPromise;
    }

    const sentryScript = document.createElement('script');
    sentryScript.src = 'https://browser.sentry-cdn.com/7.3.1/bundle.min.js';
    sentryScript.integrity =
      'sha384-07qUw81m8MXGTEXf9FQODO4Vop82t3F03SS3v3EOfrf8j7bOb/oJNg6inMDKFk2I';
    sentryScript.setAttribute('crossorigin', 'anonymous');

    this.readyPromise = new Promise((resolve, reject) => {
      sentryScript.onload = function () {
        this.createSentry();
        resolve(true);
      }.bind(this);
      sentryScript.onerror = function () {
        reject(false);
      };
    });

    document.body.appendChild(sentryScript);

    return this.readyPromise;
  }

  createSentry() {
    window.Sentry.init({
      release: 'player@' + process.env.version,
      dsn: 'https://c1f7b6b35a55486c9e4f5250a780e000@sentry.aechannel.com/7',
      sampleRate: 1,
      tracesSampleRate: 0,
      integrations: [],
      ignoreErrors: [
        // Random plugins/extensions
        "top.GLOBALS",
        // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
        "originalCreateNotification",
        "canvas.contentDocument",
        "MyApp_RemoveAllHighlights",
        "http://tt.epicplay.com",
        "Can't find variable: ZiteReader",
        "jigsaw is not defined",
        "ComboSearch is not defined",
        "http://loading.retry.widdit.com/",
        "atomicFindClose",
        // Facebook borked
        "fb_xd_fragment",
        // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
        // reduce this. (thanks @acdha)
        // See http://stackoverflow.com/questions/4113268
        "bmi_SafeAddOnload",
        "EBCallBackMessageReceived",
        // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
        "conduitPage",
      ],
      denyUrls: [
        // Facebook flakiness
        /graph\.facebook\.com/i,
        // Facebook blocked
        /connect\.facebook\.net\/en_US\/all\.js/i,
        // Woopra flakiness
        /eatdifferent\.com\.woopra-ns\.com/i,
        /static\.woopra\.com\/js\/woopra\.js/i,
        // Chrome extensions
        /extensions\//i,
        /^chrome:\/\//i,
        // Other plugins
        /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
        /webappstoolbarba\.texthelp\.com\//i,
        /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
      ],
      beforeSend: (event, hint) => {
        if (hint && hint.originalException && hint.originalException.stack) {
          const exp = hint.originalException.stack;
          if (this.checkSendingPermission(event, exp)) {
            return event;
          }
        }
      }
    });
  }

  addBreadcrumb(breadcrumb) {
    if (!Logger.enabled) {
      return;
    }
    if (window.Sentry) {
      window.Sentry.addBreadcrumb(breadcrumb)
      return;
    }

    this.breadcrumbs.push(breadcrumb);
  }

  captureEvent(error) {
    if (!Logger.enabled) {
      return;
    }
    Logger.getInstance()
      .loadSentry()
      .then((ok) => {
        if (!ok) {
          return;
        }

        if (this.breadcrumbs) {
          this.breadcrumbs.forEach((breadcrumb) => {
            window.Sentry.addBreadcrumb(breadcrumb);
          });
          this.breadcrumbs.length = 0;
        }

        window.Sentry.captureException(error);
      })
      .catch((e) => {
        logger.warn('error happened while sending a sentry report: ', e);
      });
  }

  static getInstance() {
    if (Logger.instance === null) {
      Logger.instance = new Logger();
      Logger.instance.addPermissionChecker((event, exp) => {
        return exp.toLowerCase().indexOf('player') !== -1
      });
    }

    return Logger.instance;
  }
}
