export const events = [
  'play',
  'update-uvb',
  'update-itv',
  'pause',
  'ended',
  'ready',
  'fullscreenchange',
  'timeupdate',
  'seeking',
  'seeked',
  'progress',
  'ratechange',
  'captionsenabled',
  'captionsdisabled',
  'languagechange',
  'controlshidden',
  'controlsshown',
  'loadstart',
  'loadeddata',
  'loadedmetadata',
  'qualitychange',
  'canplay',
  'canplaythrough',
  'stalled',
  'waiting',
  'emptied',
  'cuechange',
  'error',
  'volumechange'
]
