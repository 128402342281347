export default {
  controlsColor: '#e72f2f',
  vastPeriod: 86400,
  videoPreload: false,
  gaEvents: false,
  externalUrl: null,
  showSettingsButton: true,
  spots: {
    itv: {
      code: '',
      updateItvOnPause: false,
      display: false,
      style: {
        close: {
          color: '#fff',
          display: true
        },
        resume: {
          color: '#fff',
          background: '#e72f2f',
          display: true
        },
        header: {
          color: '#fff',
          background: '#e72f2f'
        }
      }
    },
    uvb: {
      code: '',
      refreshTime: 60000,
      display: false
    }
  },
  player: {
    playsinline: true,
    playbackRates: [1],
    nativeControlsForTouch: true
  }
};
