const utilities = {
  on(target, event, callback) {
    if (target) {
      target.removeEventListener(event, callback);
      target.addEventListener(event, callback);
    } else {
      console.info("target is not exist. event:" + event)
    }
  },

  off(target, event, callback) {
    if (target) {
      target.removeEventListener(event, callback);
    } else {
      console.info("target is not exist. event:" + event)
    }
  },

  isAndroid() {
    return /(android)/i.test(navigator.userAgent);
  },

  isMobile() {
    return !!navigator.userAgent.match(/Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WebOS|Windows Phone/i);
  },

  validateEmail(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  },

  getGuid() {
    function _p8(s) {
      const p = (Math.random().toString(16) + '000000000').substr(2, 8);
      return s ? '-' + p.substr(0, 4) + '-' + p.substr(4, 4) : p;
    }

    return _p8(false) + _p8(true) + _p8(true) + _p8(false);
  },

  get(url) {
    const xhr = new XMLHttpRequest();
    xhr.open('GET', url, true);
    xhr.withCredentials = false;
    try {
      xhr.send();
    } catch (e) {
      const logger = Logger.getInstance();
      logger.captureEvent(e);
    }
  },

  isObject(item) {
    return (item && typeof item === 'object' && !Array.isArray(item));
  },

  merge(target, source) {
    const output = Object.assign({}, target);

    if (this.isObject(target) && this.isObject(source)) {
      Object.entries(source).forEach(([key, value]) => {
        if (this.isObject(value)) {
          if (!(key in target)) {
            Object.assign(output, {[key]: value});
          } else {
            output[key] = this.merge(target[key], value);
          }
        } else {
          Object.assign(output, {[key]: value});
        }
      });
    }

    return output;
  },

  checkUCB() {
    return /UCBrowser/.test(navigator.userAgent);
  },

  checkJerkIE() {
    return document.documentMode <= 9;
  },

  isAnalyticsReady() {
    const LIMIT = 10;
    let tries = 0;

    return new Promise(function checkGa(resolve, reject) {
      tries++;
      if (tries >= LIMIT) {
        resolve(false);
      } else if ('ga' in window) {
        resolve(true);
      } else {
        setTimeout(() => {
          checkGa(resolve, reject);
        }, 500);
      }
    })
  }
}

export default utilities;
