import Cookies from 'js-cookie';
import utilities from '../utilities';

class Vast {
  constructor(id, vastUrl, settings, vjs, originalVideoSources, templateInstance) {
    this.vjs = vjs.vjs;
    this.vjsSrc = vjs.vjsSrc;
    this.template = templateInstance;
    this.originalVideoSources = originalVideoSources;
    this.settings = settings;
    this.vastUrl = vastUrl;
    this.brokenVast = false;
    this.vastResponse = null;
    this.vastTracker = null;
    this.vastParser = null;
    this.hasAd = false;
    this.adSkipDelay = null;
    this.adSkipSeconds = 0;
    this.isVastRequested = false;
    this.isVastViewed = false;
    this.isGoing = false;

    this.continuePlay = this.continuePlay.bind(this);
    this.onAdLinkClick = this.onAdLinkClick.bind(this);
    this.onTimeUpdate = this.onTimeUpdate.bind(this);
    this.onCanPlay = this.onCanPlay.bind(this);
    this.onPlay = this.onPlay.bind(this);
    this.onResume = this.onResume.bind(this);
    this.onPause = this.onPause.bind(this);
    this.onSkip = this.onSkip.bind(this);
    this.onVolumeChange = this.onVolumeChange.bind(this);

    utilities.on(this.template.skipButton, 'click', this.onSkip);
  }

  async loadVast() {
    if (this.isVastRequested) return;
    this.isGoing = true;
    this.isVastRequested = true;
    const {VASTTracker, VASTParser} = await import('@dailymotion/vast-client');
    this.vastParser = new VASTParser();

    this.vastParser.on('VAST-error', async ({ERRORCODE}) => {
      this.sendError(ERRORCODE);
    });

    const load = async (url, onFail) => {
      try {
        this.vastResponse = await this.vastParser.getAndParseVAST(url, {
          wrapperLimit: 7,
          timeout: 5000,
          withCredentials: true
        });
      } catch (e) {
        onFail();
        return;
      }
      this.hasAd = this.vastResponse && this.vastResponse.ads.length > 0;
    }

    await load(this.vastUrl, () => {
      this.brokenVast = true;
      this.sendError();
    });

    if (!this.hasAd) {
      await load('https://tsyndicate.com/do2/8d459d9f1f034fbc91f1a61d469bfd35/vast?', () => {
        this.brokenVast = true;
        this.sendError();
        this.recover();
      });
    }

    if (!this.hasAd) {
      this.recover();
      return;
    }

    const [creative, adVideo, adIndex] = this.findSupportedCreative();

    if (!creative || !adVideo) {
      this.recover();
      return;
    }

    this.vastTracker = new VASTTracker(this.vastParser, this.vastResponse.ads[adIndex], creative);
    this.initVastTrackerEvents(creative.trackingEvents);
    this.template.openAdLink.setAttribute('href', creative.videoClickThroughURLTemplate && creative.videoClickThroughURLTemplate.url || '');
    utilities.on(this.template.openAdLink, 'click', this.onAdLinkClick);
    this.adSkipDelay = creative.skipDelay || 0;

    this.vjs.one('canplay', this.onCanPlay);
    this.vjs.on('play', this.onPlay);
    this.vjs.on('pause', this.onPause);
    this.vjs.on('timeupdate', this.onTimeUpdate);
    this.vjs.on('volumechange', this.onVolumeChange);

    this.vjsSrc([{
      src: adVideo.fileURL,
      type: adVideo.mimeType
    }]);

    return this.vjs.play();
  }

  continuePlay() {
    if (document.visibilityState === 'visible') {
      this.vjs.play();
      utilities.off(document, 'visibilitychange', this.continuePlay);
    }
  }

  onTimeUpdate() {
    this.template.skipButtonValue.innerHTML = Math.ceil(this.adSkipSeconds);
    this.template.skipButton.classList[this.adSkipSeconds > 0 ? 'add' : 'remove']('ae-player__skip--inactive');
    this.template.skipButtonCount.classList[this.adSkipSeconds > 0 ? 'remove' : 'add']('ae-player__skip-count--hidden');
    this.adSkipSeconds = this.adSkipDelay - this.vjs.currentTime();
    this.vastTracker.setProgress(this.vjs.currentTime());
    if (this.isVastViewed) return;

    if (this.adSkipSeconds <= 0) {
      this.isVastViewed = true;
      Cookies.set('vast', '1', {expires: this.vastExpiringDays()});
    }
  }

  onAdLinkClick() {
    this.vastTracker.click();

    this.vjs.pause();
    if (utilities.isMobile()) {
      utilities.on(document, 'visibilitychange', this.continuePlay);
    }
  }

  onCanPlay() {
    this.template.hideLoader();
    this.vjs.play();
    this.template.adPlay();
    this.template.showSkipButton();
    this.vastTracker.trackImpression();
    this.autoSkipTimerFunc();
  }

  autoSkipTimer = null

  autoSkipTimerFunc() {
    let baseTimeout = 30000;
    if (utilities.checkUCB()) { // поскольку в УЦ браузере нереально вывести что-то поверх видео, то скипаем прерол автоматически после 5 сек
      baseTimeout = 5000;
    }

    this.autoSkipTimer = setTimeout(() => {
      this.onSkip();
    }, baseTimeout-this.vjs.currentTime()*1000);
  }

  clearAutoSkipTimer() {
    if (this.autoSkipTimer !== null) {
      clearTimeout(this.autoSkipTimer);
      this.autoSkipTimer = null;
    }
  }

  onPlay() {
    this.vjs.on('play', this.onResume);
  }

  onResume() {
    this.vastTracker.setPaused(false);
    this.autoSkipTimerFunc();
  }

  onPause() {
    this.vastTracker.setPaused(true);
    this.clearAutoSkipTimer();
  }

  onSkip() {
    this.vastTracker.skip();
    this.recover();
  }

  onVolumeChange() {
    this.vastTracker.setMuted(this.vjs.muted());
  }

  getADVideo(mediaFiles) {
    let files = mediaFiles.filter(file => !file.mimeType.includes('flv'));

    if (!files.length) {
      return null;
    }

    return files.reduce((prev, current) => {
      return prev.width > current.width ? prev : current;
    });
  }

  sendError(code) {
    if (this.vastResponse && this.vastResponse.errorURLTemplates && this.vastResponse.errorURLTemplates.length) {
      this.vastTracker.error({
        CONTENTURI: this.vastResponse.errorURLTemplates[0],
        ERRORCODE: code || 900
      })
    }
  }

  canRunAds() {
    if (this.settings.canRunAds !== undefined) {
      return this.settings.canRunAds;
    }
    return window.canRunAds;
  }

  isInitialVast() {
    return !Cookies.get('vast') && this.vastResponse === null && this.vastUrl && !this.brokenVast && this.canRunAds();
  }

  vastExpiringDays() {
    return this.settings.vastPeriod / 86400;
  }

  findSupportedCreative() {
    const result = [null, null, 0]; // [creative, adVideo, adIndex]

    const breaker = 'break';

    try {
      this.vastResponse.ads.forEach(ad => {
        if (!ad.creatives.length) return;
        ad.creatives.forEach(creative => {
          result[0] = creative;

          const adVideo = this.getADVideo(creative.mediaFiles);

          const adVideoSupported = !(!adVideo || (adVideo.apiFramework !== null && adVideo.apiFramework.toLowerCase() === 'vpaid'))

          if (adVideoSupported) {
            result[1] = adVideo;
            throw breaker;
          }
        });

        result[2]++; // increase active ad index
      })
    } catch (e) {
      if (e === breaker) {
        return result;
      }
    }

    return result;
  }

  initVastTrackerEvents(events) {
    Object.keys(events).forEach(name => {
      this.vastTracker.on(name, () => {
        console.log('vast - ' + name);
      });
    });
  }

  recover() {
    this.hasAd = false;
    this.isGoing = false;
    this.template.adStop();
    this.template.hideSkipButton();
    this.setupOriginalVideo();
    this.destroy();
  }

  setupOriginalVideo() {
    this.vjsSrc(this.originalVideoSources.map(el => ({
      src: el.src,
      type: el.type
    })));
    this.vjs.play();
  }

  destroy() {
    this.clearAutoSkipTimer();
    utilities.off(document, 'visibilitychange', this.continuePlay);
    utilities.off(this.template.openAdLink, 'click', this.onAdLinkClick);
    utilities.off(this.template.skipButton, 'click', this.onSkip);
    this.vjs.off('play', this.onPlay);
    this.vjs.off('play', this.onResume);
    this.vjs.off('timeupdate', this.onTimeUpdate);
    this.vjs.off('pause', this.onPause);
    this.vjs.off('canplay', this.onCanPlay);
    this.vjs.off('volumechange', this.onVolumeChange);
  }
}

export default Vast;
