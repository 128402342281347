import BaseTemplate from './BaseTemplate';

export default class Redirect extends BaseTemplate {
  static templates = {
    goButton: `
      <div class="ae-player__resume ae-player__resume--external js-ae-player-go-external">
        <button data-url="{uxternalUrl}" target="_blank">
          Check video on other site ➡️
        </button>
      </div>
    `
  }

  goButton = null;
  settings = {}

  constructor(settings) {
    super();

    this.settings = settings;

    this.videoContainer = settings.videoContainer;
    this.newWindowUrl = settings.newWindowUrl;
    const newTemplate = Redirect.templates.goButton.replace('{uxternalUrl}', settings.newWindowUrl);
    this.goButton = this.createElement(newTemplate);
    this.goButton.children[0].style.backgroundColor = '#e82f2e';
    this.goButton.children[0].style.color = '#fff';
    this.goButton.addEventListener('click', () => {
      window.open(settings.newWindowUrl, '_blank')
    })
  }

  showGoButton() {
    this.videoContainer.appendChild(this.goButton);
  }
}
