export default {
  WRAPPER: '<div class="ae-player visible {playerIdClass}"></div>',

  OPEN_AD_LINK: '<a href="#" target="_blank" class="ae-player__go ae-player__go--hidden"></a>',

  SKIP_BUTTON: `
    <div class="ae-player__skip">
      Skip Ad
      <span class="ae-player__skip-count ae-player__skip-count--hidden">in <span class="ae-player__skip-value"></span></span>
      <svg width="24" height="24" viewBox="0 0 24 24">
        <path fill="currentColor" d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z" />
      </svg>
    </div>
  `,

  ITV_BANNER: `
    <div class="ae-player__itv">
      <div>
        <div class="ae-player__itv-title">
          Advertisement
          <button>
            <svg width="24" height="24" viewBox="0 0 24 24">
              <path fill="currentColor" d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" />
            </svg>
          </button>
        </div>
        <div class="ae-player__itv-spot"></div>
      </div>
      <div class="ae-player__resume">
        <button>
          Resume
        </button>
      </div>
    </div>
  `,

  UVB_BANNER: `
    <div class="ae-player__bottom">
      <button>
        <svg width="24" height="24" viewBox="0 0 24 24">
          <path fill="currentColor" d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" />
        </svg>
      </button>
      <div class="ae-player__bottom-spot"></div>
    </div>
  `,

  SETTINGS: `
    <div class="ae-player__settings vjs-control">
      <button class="ae-player__settings-button">
        <svg style="width:20px;height:20px" viewBox="0 0 24 24">
          <path fill="currentColor" d="M12,15.5A3.5,3.5 0 0,1 8.5,12A3.5,3.5 0 0,1 12,8.5A3.5,3.5 0 0,1 15.5,12A3.5,3.5 0 0,1 12,15.5M19.43,12.97C19.47,12.65 19.5,12.33 19.5,12C19.5,11.67 19.47,11.34 19.43,11L21.54,9.37C21.73,9.22 21.78,8.95 21.66,8.73L19.66,5.27C19.54,5.05 19.27,4.96 19.05,5.05L16.56,6.05C16.04,5.66 15.5,5.32 14.87,5.07L14.5,2.42C14.46,2.18 14.25,2 14,2H10C9.75,2 9.54,2.18 9.5,2.42L9.13,5.07C8.5,5.32 7.96,5.66 7.44,6.05L4.95,5.05C4.73,4.96 4.46,5.05 4.34,5.27L2.34,8.73C2.21,8.95 2.27,9.22 2.46,9.37L4.57,11C4.53,11.34 4.5,11.67 4.5,12C4.5,12.33 4.53,12.65 4.57,12.97L2.46,14.63C2.27,14.78 2.21,15.05 2.34,15.27L4.34,18.73C4.46,18.95 4.73,19.03 4.95,18.95L7.44,17.94C7.96,18.34 8.5,18.68 9.13,18.93L9.5,21.58C9.54,21.82 9.75,22 10,22H14C14.25,22 14.46,21.82 14.5,21.58L14.87,18.93C15.5,18.67 16.04,18.34 16.56,17.94L19.05,18.95C19.27,19.03 19.54,18.95 19.66,18.73L21.66,15.27C21.78,15.05 21.73,14.78 21.54,14.63L19.43,12.97Z" />
        </svg>
      </button>
    </div>
  `,

  SETTINGS_MENU: `
    <div class="ae-player__menu">
      <button>
        <svg width="24" height="24" viewBox="0 0 24 24">
          <path fill="currentColor" d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" />
        </svg>
      </button>
      <div>
        <div class="ae-player__speed">
          <div class="ae-player__menu-open ae-player__menu-open-speed">
            Speed
            <div>
              <span class="ae-player__menu-value ae-player__menu-speed-value"></span>
              <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                <path fill="currentColor" d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z" />
              </svg>
            </div>
          </div>
          <div class="ae-player__menu-inner ae-player__menu-speed">
            <div class="ae-player__back ae-player__back--speed">
              <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                <path fill="currentColor" d="M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z" />
              </svg>
              Speed
            </div>
          </div>
        </div>
        <div class="ae-player__quality">
          <div class="ae-player__menu-open ae-player__menu-open-quality">
            Quality
            <div>
              <span class="ae-player__menu-value ae-player__menu-quality-value"></span>
              <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                <path fill="currentColor" d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z" />
              </svg>
            </div>
          </div>
          <div class="ae-player__menu-inner ae-player__menu-quality">
            <div class="ae-player__back ae-player__back--quality">
              <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                <path fill="currentColor" d="M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z" />
              </svg>
              Quality
            </div>
          </div>
        </div>
      </div>
    </div>
  `,

  LOADER: `
    <div class="ae-player__loader"></div>
  `
};
