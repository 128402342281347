import utilities from "../utilities";

export default class Events {
  constructor(settings) {
    this.settings = settings;
  }

  /**
   * arguments: 'video', 'play', 'hls' (category, action, label, {})
   */
  send() {
    if (!this.settings.gaEvents) return;
    const args = Array.prototype.slice.call(arguments);

    utilities.isAnalyticsReady().then((ready) => {
      if (ready) {
        window.ga.apply(null, ['send', 'event', ...args]);
      } else {
        console.log('Sending event:', arguments);
      }
    });
  }

  sendNonInteractive() {
    this.send(...Array.prototype.slice.call(arguments), {
      nonInteraction: true
    });
  }

  sendHLSEvent() {
    this.sendNonInteractive('video', 'play', 'hls');
  }

  sendMP4Event() {
    this.sendNonInteractive('video', 'play', 'mp4');
  }
};
